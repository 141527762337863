import React from "react"

const PageTitle = ({ title, description }) => {
  return (
    <div className="page-banner-area page-contact" id="page-banner">
      <div className="overlay dark-overlay"></div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 m-auto text-center col-sm-12 col-md-12">
            <div className="banner-content content-padding">
              <h1 className="text-white">{title}</h1>
              <p>{description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageTitle
