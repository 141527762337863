import React from "react"
import { Link, graphql } from "gatsby"

import SEO from "../components/seo"
import PageTitle from "../components/page-title"
import Layout from "../components/layout"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges
    const { currentPage, numPages } = this.props.pageContext
    const isLast = currentPage === numPages
    console.log(currentPage)
    const prevPage =
      currentPage <= 2
        ? "/insights"
        : `/insights/page/${(currentPage - 1).toString()}`
    const nextPage = `/insights/page/${(currentPage + 1).toString()}`

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={siteTitle}
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <PageTitle title="Insights" description="" />
        <section class="section blog-wrap ">
          <div class="container">
            <div className="row">
              {posts.map(({ node }, index) => {
                const title = node.frontmatter.title || node.fields.slug
                return (
                  <div className="col-lg-4 col-md-6" key={index}>
                    <div className="blog-post">
                      <img
                        src="/images/blog/blog-1.jpg"
                        alt=""
                        className="w-100"
                      />
                      <div className="mt-4 mb-3 d-flex">
                        <div className="post-author mr-3">
                          <i className="fa fa-user"></i>
                          <span className="h6 text-uppercase">
                            Craig Pickles
                          </span>
                        </div>

                        <div className="post-info">
                          <i className="fa fa-calendar-check"></i>
                          <span>
                            {node.frontmatter.day} {node.frontmatter.month}{" "}
                            {node.frontmatter.year}
                          </span>
                        </div>
                      </div>
                      <Link to={node.fields.slug} className="h4 ">
                        {title}
                      </Link>
                      <p
                        className="mt-3"
                        dangerouslySetInnerHTML={{
                          __html: node.frontmatter.description || node.excerpt,
                        }}
                      />
                      <Link to={node.fields.slug} className="read-more">
                        Read More <i className="fa fa-angle-right"></i>
                      </Link>
                    </div>
                  </div>
                )
              })}
            </div>
            <div class="postsFooter" style={{ clear: "both" }}>
              <nav class="pagination" role="navigation">
                {currentPage > 1 && (
                  <Link to={prevPage} rel="prev" class={"newer-posts mr-2"}>
                    <span aria-hidden="true">←</span> Newer Posts
                  </Link>
                )}
                <span class="page-number mr-2">
                  Page {currentPage} of {numPages}
                </span>
                {!isLast && (
                  <Link to={nextPage} rel="next" class={"older-posts"}>
                    Older Posts <span aria-hidden="true">→</span>
                  </Link>
                )}
              </nav>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            day: date(formatString: "DD")
            month: date(formatString: "MMM")
            year: date(formatString: "YYYY")
            title
          }
        }
      }
    }
  }
`
